
import { IconBrandCss3, IconBrandGithub, IconBrandJavascript, IconBrandTypescript, IconEye, IconGripHorizontal } from '@tabler/icons-react'
import './blog_post.css'
import {Link,Outlet} from 'react-router-dom'
import { updateStateStore, getStateStore, useStateStoreProvider } from '../react-state-store'



export const BlogPost = ({title,link})=>{

    return(
        <a href='/' className="blog-post">
            <div className="row1">
                <img />
            </div>
            <h3 className="row2">{title}</h3>
        </a>
    )
}
export const ProjectPost = ({title,message,link,showPreview})=>{
    const smallCaseTitle = title.toLowerCase();
    link = link?link:`https://github.com/KBismark/${smallCaseTitle}`;
    return(
        <article className="project-post">
            <div className="row1">
                <h3><a rel="noreferrer" target="_blank" href={link}>{title}</a></h3>
                <div style={{alignItems:'center',display:'flex'}}>
                    {
                        showPreview&&
                        <Link preventScrollReset={!false} to={`/projects/${smallCaseTitle}`} style={{display:'flex',alignItems:'center',color:'var(--white-color)',padding:'7px 10px',backgroundColor:'var(--dark-light-red)',borderRadius:10}}>
                            <IconEye size={26}/>
                            <span style={{marginLeft:4}}>Preview</span>
                        </Link>
                    }
                    <a href={link} rel="noreferrer" target="_blank" 
                        style={{display:'flex',alignItems:'center',color:'var(--white-color)',padding:'7px 10px',backgroundColor:'var(--dark-light-red)',borderRadius:10,marginLeft:20}}>
                        <IconBrandGithub />
                        <span style={{marginLeft:4}}>Source</span>

                    </a>
                    
                </div>
            </div>
            <p className="row3" style={{textAlign: 'justify'}}>{message} </p>
        </article>
    )
}

