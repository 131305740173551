import { IconBrandX, IconMenuDeep } from '@tabler/icons-react';
import './header.css';
import { useStateStoreProvider } from '../react-state-store';


export const Header = ()=>{
    const provider = useStateStoreProvider();
    console.log(provider, ' Head');
    return (
        <header style={{backgroundColor:'#000000'}} className='main-header bg-dark'>
            <div className='logo-container'><a className='logo' href='/'><span style={{color:'rgb(var(--primary))'}}>kbis</span>/space</a></div>
            <div className='small-screen-right'>
                <IconMenuDeep />
            </div>
            <div className='big-screen-right'>
                <a href='/#blogs' style={{display:'none'}} >Blogs</a>
                <a href='/#projects'>Projects</a>
                <a target='_blank' href='https://twitter.com/KBismark_' className='xlink' >
                    <IconBrandX /> <span>@KBismark_</span>
                </a>
            </div>
        </header>
    )
}