import { useState } from "react"
/**
 * 
 * @param {{message:{npm:string;yarn:string},showHeader:true}|{message:string,showHeader:false}} param0 
 * @returns 
 */
export const InstallationCodeContainer = ({message,showHeader})=>{
    const [state,setState] = useState({choice:'npm'})
    const selectChoice = ()=>setState({...state,choice:state.choice==='npm'?'yarn':'npm'})
    return (
        <div className="code-container">
            {showHeader&&<div className="space-hint">
                {
                    state.choice==='npm'?
                    <>
                    <span onClick={selectChoice} className='emphasize emphasized-border'>npm</span>
                    <span onClick={selectChoice}>yarn</span>
                    </>:
                    <>
                    <span onClick={selectChoice}>npm</span>
                    <span onClick={selectChoice} className='emphasize emphasized-border'>yarn</span>
                    </>
                }
                
            </div>}
            <div className="codespace">
                {showHeader?message[state.choice]:message}
            </div>
        </div>
    )
}