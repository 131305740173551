
export const _4 = `<div class="xmk-js1x"> 
  <span class="xmk-key1x">const</span> <span class="xmk-varx">App</span> = ()=&#62;&#123;
      <span class="xmk-commentx">&#47;&#47; Prevent regeneration of new scoped name on re-render
</span>      <span class="xmk-key1x">const</span> <span class="xmk-varx">scoped</span> = <span class="xmk-methx">useMemo</span>(()=&#62;<span class="xmk-methx">getScopedName</span>(<span class="xmk-stringx">'App'</span>),[]);
      <span class="xmk-commentx">&#47;&#47; rest of your code...
</span>  &#125;

 </div>`; // \insert s_name   



export const _5 = `<div class="xmk-js1x"> 
  <span class="xmk-key1x">const</span> <span class="xmk-varx">App</span> = ()=&#62;&#123;
      <span class="xmk-key1x">const</span> [ <span class="xmk-varx">theme</span>, <span class="xmk-varx">setTheme</span> ] = <span class="xmk-methx">useState</span>(<span class="xmk-stringx">'light'</span>)
      <span class="xmk-key1x">const</span> <span class="xmk-varx">color</span> = <span class="xmk-stringx">&#96;≎06324215133523154_#123;theme==='light'?'black':'white'&#125;&#96;</span>;
      <span class="xmk-commentx">&#47;&#47; Prevent regeneration of new scoped name on re-render
</span>      <span class="xmk-key1x">const</span> <span class="xmk-varx">scoped</span> = <span class="xmk-methx">useMemo</span>(()=&#62;<span class="xmk-methx">getScopedName</span>(<span class="xmk-stringx">'App'</span>),[]);
      <span class="xmk-commentx">&#47;&#47; Get keys and style sheet
</span>      <span class="xmk-key1x">const</span> &#123; <span class="xmk-varx">keys</span>, <span class="xmk-varx">sheet</span> &#125; = <span class="xmk-methx">useScopedStyleSheet</span>(&#123;
          <span class="xmk-stringx">'.container'</span>:&#123;
              <span class="xmk-varx">opacity</span>: 0.9,
              <span class="xmk-varx">margin</span>: <span class="xmk-stringx">'10px'</span>,
              <span class="xmk-commentx">&#47;&#47; Dynamic values must be set inside template literals
</span>              <span class="xmk-varx">color</span>: <span class="xmk-stringx">&#96;≎06324215133523154_#123;color&#125;&#96;</span>
          &#125;
      &#125;,<span class="xmk-varx">scoped</span>)
      <span class="xmk-commentx">&#47;&#47; rest of your code...
</span>
      <span class="xmk-key2x">return</span> (
          &#60;<span class="xmk-key1x">div</span> <span class="xmk-varx">className</span>=&#123;<span class="xmk-varx">keys</span>[<span class="xmk-stringx">'.container'</span>]&#125;&#62;
              &#60;<span class="xmk-key1x">h1</span>&#62;<span class="xmk-text">Hello world</span>&#60;&#47;<span class="xmk-key1x">h1</span>&#62;
              &#60;<span class="xmk-varx">ScopedStyleSheets</span> <span class="xmk-varx">styles</span>=&#123;<span class="xmk-varx">sheet</span>&#125; &#47;&#62;
          &#60;&#47;<span class="xmk-key1x">div</span>&#62;
      )
   &#125;
  
 </div>`; // \insert maim_ss   

 
