import { IconAlertTriangleFilled, IconBrandGithub } from "@tabler/icons-react";
import { InstallationCodeContainer } from "./global";
import { Header } from "./header";
import './project.css'
import '../xyntax-theme.css'
import { _1, _2, _3 } from "../codes/stylescope";
import { _4, _5 } from "../codes/stylescope-2";
import '../codes/stylescope.xmd';
import { useParams } from "react-router-dom";
import { useLayoutEffect } from "react";


export function ProjectPage({githubSource}){
    const {name} = useParams();

    useLayoutEffect(()=>{
       const prevScrollPosition = window.scrollY;
       window.scrollTo({'behavior':'instant','top':0})
       
    },[])

    return (
        <div id="project-page-container" style={{position:'relative'}}>
            <Header/>
            <div className="wrapper">
                <div className="blog-page-content">
                    <div className="aside-left"></div>
                    <div className="main-content">
                        <div style={{marginBottom: '4%'}}>
                            <h1>Scoping styles to react components with React-Stylscope</h1>
                            <div>
                                <p>
                                    React-stylescope is a component based styling library for react. It allows you to write styles that are scoped or tied to 
                                     a component in a react application. React-stylescope is a react implementation of the "@scope" CSS at-rule with support for 
                                     all browsers that can run a react application.
                                </p>
                                <p>
                                    There are solutions like <a rel='noreferrer' target="_blank" href="https://github.com/styled-components"> styled-components </a>. 
                                    Styled-components is a component based styling library and one of the good solutions out there to 
                                    help write styles that are scoped or tied to components. However, wouldn't it be easier to write 
                                    styles with auto completions out of the box than writing as strings? <br/>
                                    React-stylescope allows you to style your components with ease and also get auto completion features out of the box. 
                                    No more pain writing styles as strings. React-stylescope takes out that burden. It transpiles the styles you write into strings 
                                    for you during code compilation by react.
                                </p>
                                <h2 id="installation">Installation</h2>
                                All you will need to start writing styles that are scoped to components is to install the 
                                <strong> react-stylescope </strong> package.
                                <div style={{marginTop:15,marginBottom:15}}>
                                    <InstallationCodeContainer showHeader={true} message={{
                                        npm:'npm install react-stylescope',
                                        yarn:'yarn add react-stylescope'
                                    }}/>
                                </div>
                                <h2 id="how_to_use_xyntax">How to use react-stylescope</h2>
                                <p>
                                    After installation, if the project was created with <em>create-react-app</em> navigate to the project directory 
                                    and run <em>npx stylescope --setup react</em> <br/><br/>
                                    Else, add the code below to the end of the <em>module.rules</em> array in the 
                                    <em>webpack.config.js</em> and run <em>npx stylescope --setup device</em>
                                </p>
                                 <div className="code-container">
                                    <div className="space-hint">
                                        <span>./webpack.config.js</span>
                                    </div>
                                    <pre className="xyntax" dangerouslySetInnerHTML={{__html:_1}}></pre>
                                </div>
                                <h2>Using Vite?</h2> 
                                <p>
                                    Import the <em>ScopedStyleVitePlugin</em> and add to the plugins array in the <em>vite.config.js</em> 
                                    file and run <em>npx stylescope --setup device</em>
                                </p>
                                <div className="code-container">
                                    <div className="space-hint">
                                        <span>./vite.config.js</span>
                                    </div>
                                    <pre className="xyntax" dangerouslySetInnerHTML={{__html:_2}}></pre>
                                </div>
                                <p>Let's try to solve the card issue between Team A and Team B with react-stylescope:</p>
                                <div className="code-container">
                                    <pre className="xyntax" dangerouslySetInnerHTML={{__html:_3}}></pre>
                                </div>

                                <p>API documentation</p>

                                <h2>getScopedName</h2>
                                <p> 
                                    Returns a <em>scoped</em> name unique to the calling component. The value returned 
                                    must be stored in a variable named <em>scoped</em>. <strong>This is a requirement</strong>  
                                    <ul>
                                        <li>
                                            <strong>@param </strong> <em>name</em>: <strong style={{fontSize:'90%'}}>string</strong> 
                                             A unique name for the component. It may be the name of the component.
                                        </li>
                                    </ul>
                                    <strong>Do not forget to name your variable <em>scoped</em></strong>
                                </p>
                                <div className="code-container">
                                    <pre className="xyntax" dangerouslySetInnerHTML={{__html:_4}}></pre>
                                </div>

                                <h2>ScopedStyleSheets</h2>
                                <p> 
                                    A component that renders a style element with your style sheet.  
                                    <ul>
                                        <li>
                                            <strong>@param </strong> <em>props.styles</em>: <strong style={{fontSize:'90%'}}>string</strong> 
                                            Pass the style sheet to the styles prop
                                        </li>
                                    </ul>
                                </p>

                                <h2>useScopedStyleSheet</h2>
                                <p> 
                                    Creates a css string from a React CSSProperties object. The style object passed to 
                                    <em>useScopedStyleSheet</em> does not get to the browser. It's transpiled into strings before the code gets served to the browser. 
                                    The function only returns a pre-computed object when your application runs in the browser. 
                                    All dirty works (conversion of the styles object to css string) is done before the code gets to the browser. 
                                    <ul>
                                        <li>
                                            <strong>@param </strong> <em>styleSheet</em>: <strong style={{fontSize:'90%'}}>string</strong> 
                                            Styles object.
                                        </li>
                                        <li>
                                            <strong>@param </strong> <em>marker</em>: <strong style={{fontSize:'90%'}}>scoped</strong> 
                                            Pass the <em>scoped</em> variable here. Anything other than the varible named <em>scoped</em> won't work. 
                                        </li>
                                    </ul>
                                </p>
                                <div className="code-container">
                                    <pre className="xyntax" dangerouslySetInnerHTML={{__html:_5}}></pre>
                                </div>
                                
                                <h2>Setting style values dynamically</h2>
                                <p>
                                    In your style objects passed to <em>useScopedStyleSheet</em>, when setting the values dynamically, do so only in template literals. 
                                    See examples on <a href="https://github.com/KBismark/react-stylescope#setting-style-values-dynamically" rel="noreferrer" target="_blank">setting style values dynamically</a>
                                </p>
                                
                                <p className="attention" style={{marginTop: 50,alignItems: 'center',display:'flex'}} >
                                    <IconAlertTriangleFilled />
                                    <span style={{marginLeft: 10,marginRight:6}}>
                                        Code highlighting on this page was made with 
                                        <a href='https://github.com/KBismark/xyntax' rel="noreferrer" target="_blank"> Xyntax </a>
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div>
                            <div style={{justifyContent:'flex-end',position:'relative'}} className='wrapper bg-dark image-header'>
                                <div className='blog-page-content-briefing'>
                                    <div className='brief'>
                                        <h1 className='welcome'>React Stylescope</h1>
                                        <div className='brief-message'>
                                            Component based styling for React. A react implementation 
                                            of @scope in CSS. A "styled-components" alternative.
                                        </div>
                                        <div className="project-source" style={{display:'flex',justifyContent:'flex-end',marginRight:'2%'}}>
                                            <a href='https://github.com/KBismark/react-stylescope' rel="noreferrer" target="_blank" style={{display:'flex',alignItems:'center',color:'var(--white-color)',padding:'6px 15px',background:'rgb(var(--primary))',borderRadius:10}}>
                                                <IconBrandGithub />
                                                <span style={{marginLeft:4}}>Source</span>

                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 style={{fontSize:'2em',marginTop:'2%'}}>Overview</h2>
                            <div>
                                <p>
                                    React allows you to write components to define your app's UI. Every component have its own logic and state 
                                    which allows for a dclarative and mantainable codebase. However, how do we style those components? What if 
                                    the are differnt teams working on different parts of the application? How do we solve the confusion of CSS 
                                    name conflicts easily? Team A sets <em>{'.card{ background-color: white;}'}</em> Team B thinks 
                                    <em>{'.card{ background-color: blue;}'}</em> 
                                    works perfectly for them. Both Teams push to production. What happens next? Disaster!!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div></div>
                    <div className="aside-right">
                        <h4>Overview</h4>
                        <h4>Syntax Highlighting With Xyntax</h4>
                        <div>
                            <h4>Installation</h4>
                            <h4>How to use xyntax</h4>
                            <h4>Usage with React</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}