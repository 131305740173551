

export const _1 = `<div class="xmk-js1x"> 
  <span class="xmk-key2x">import</span> <span class="xmk-varx">React</span> <span class="xmk-key2x">from</span> <span class="xmk-stringx">'react'</span> 
  
  <span class="xmk-commentx">&#47;&#47; This is my button
</span>  <span class="xmk-key1x">function</span> <span class="xmk-methx">MyButton</span>() &#123;
      <span class="xmk-key2x">return</span> &#60;<span class="xmk-key1x">button</span>&#62;<span class="xmk-text">Click me</span>&#60;&#47;<span class="xmk-key1x">button</span>&#62;
  &#125;
 </div>`; // \insert code_1 

 export const _2 = `<div class="xmk-js1x"> 
  <span class="xmk-key2x">export</span> <span class="xmk-key1x">const</span> <span class="xmk-varx">Code</span> = <span class="xmk-stringx">&#96;&#96;</span> <span class="xmk-commentx">&#47;&#47; \\insert my_button
</span>   </div>`; // \insert code_2 

 export const _3 = `<div class="xmk-js1x"> 
  <span class="xmk-commentx">&#47;&#47;&#60;xynt\ax path=\<span class="xmk-stringx">".&#47;src&#47;pre-highlighted-codes.js"</span> my_button&#62;
</span>  &#123; 
      &#47;\&#47; \\write <span class="xmk-key2x">import</span> <span class="xmk-varx">React</span> <span class="xmk-key2x">from</span> <span class="xmk-stringx">'react'</span> 
      
      <span class="xmk-commentx">&#47;&#47; This is my button
</span>      <span class="xmk-key1x">function</span> <span class="xmk-methx">MyButton</span>() &#123;
          <span class="xmk-key2x">return</span> &#60;<span class="xmk-key1x">button</span>&#62;<span class="xmk-text">Click me</span>&#60;&#47;<span class="xmk-key1x">button</span>&#62;
      &#125;
  &#125; 
  <span class="xmk-commentx">&#47;&#47;&#60;\&#47;xyntax&#62;
</span> </div>`; // \insert code_3 

 