
//<xyntax path="./src/codes/stylescope.js"  webpack_config>
{
    const config =
    {
        // \write loader: require.resolve('react-stylescope/dist/lib/loader.js') \n
    }

}
//</xyntax>


const defineConfig = ()=>{};
const react = defineConfig;
const ScopedStyleVitePlugin = defineConfig;

//<xyntax path="./src/codes/stylescope.js"  vite_config>
{
    // \write import { ScopedStyleVitePlugin } from "react-stylescope/dist/lib/vite-loader" \n

    // A possible config may looked like this
     // \write export default 
    defineConfig({
        plugins: [react(), ScopedStyleVitePlugin()] // Added to the plugin array
    });
    
}
//</xyntax>

const useScopedStyleSheet = defineConfig;
const getScopedName = defineConfig;
const ScopedStyleSheets = (props)=><></>;
const useMemo = defineConfig;
const useState = defineConfig;

//<xyntax path="./src/codes/stylescope.js"  card_example>
{
    // \write import { useScopedStyleSheet, getScopedName, ScopedStyleSheets } from 'react-stylescope' \n

    const Card = ({backgroundColor, caption})=>{

        const scoped = getScopedName('card')

        const { keys, sheet } = useScopedStyleSheet({
            '.card-container': {
                width: '320px',
                backgroundColor: `${backgroundColor}`
            },
            '.card-container img':{
                lineHeight: 1.5,
                display: 'block',
                margin: '10px'
            }
        }, scoped)

        return (
            <div className={keys['.card-container']} >
                <div><img src='image.png' alt='Some text'  /></div>
                <div>{caption}</div>
                <ScopedStyleSheets styles={sheet} />
            </div>
        )
    }

}
//</xyntax>

//<xyntax path="./src/codes/stylescope-2.js"  s_name>
{
    const App = ()=>{
        // Prevent regeneration of new scoped name on re-render
        const scoped = useMemo(()=>getScopedName('App'),[]);
        // rest of your code...
    }

}
//</xyntax>


//<xyntax path="./src/codes/stylescope-2.js"  maim_ss>
{
    const App = ()=>{
        const [ theme, setTheme ] = useState('light')
        const color = `${theme==='light'?'black':'white'}`;
        // Prevent regeneration of new scoped name on re-render
        const scoped = useMemo(()=>getScopedName('App'),[]);
        // Get keys and style sheet
        const { keys, sheet } = useScopedStyleSheet({
            '.container':{
                opacity: 0.9,
                margin: '10px',
                // Dynamic values must be set inside template literals
                color: `${color}`
            }
        },scoped)
        // rest of your code...

        return (
            <div className={keys['.container']}>
                <h1><x-text>Hello world</x-text></h1>
                <ScopedStyleSheets styles={sheet} />
            </div>
        )
     }
    
}
//</xyntax>




