import React, { useEffect } from "react";

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};

var LEAST_NUMBER = -99999999;
var BIGSTORE = {
    count: LEAST_NUMBER,
    stores: {},
    track: []
};
function noOperation() { }
function TrackPopper() {
    BIGSTORE.track.pop();
    return null;
}
var StateStoreProvider = /** @class */ (function (_super) {
    __extends(StateStoreProvider, _super);
    function StateStoreProvider(props) {
        var _this = _super.call(this, props) || this;
        var providerId = props.providerId;
        BIGSTORE.track.push(providerId);
        BIGSTORE.stores[providerId] = {};
        console.log(BIGSTORE);
        return _this;
    }
    StateStoreProvider.prototype.componentWillUnmount = function () {
        BIGSTORE.stores[this.props.providerId] = null;
    };
    StateStoreProvider.prototype.render = function () {
        return (React.createElement(React.Fragment, null,
            this.props.children,
            React.createElement(TrackPopper, null)));
    };
    return StateStoreProvider;
}(React.Component));
export { StateStoreProvider };
export function StateStoreProviders(_a) {
    var children = _a.children, providerId = _a.providerId;
    var id = undefined;
    if (typeof providerId == 'string') {
        id = providerId;
    }
    else {
        id = BIGSTORE.count++;
    }
    ;
    BIGSTORE.track.push(id);
    BIGSTORE.stores[id] = {};
    useEffect(function () {
        return function () {
            BIGSTORE.stores[id] = null;
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        children,
        React.createElement(TrackPopper, null)));
}
var Boundary = /** @class */ (function (_super) {
    __extends(Boundary, _super);
    function Boundary() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Boundary.prototype.shouldComponentUpdate = function (nextProps, nextState, nextContext) {
        return false;
    };
    Boundary.prototype.render = function () {
        return (React.createElement(React.Fragment, null, this.props.children));
    };
    return Boundary;
}(React.Component));
export { Boundary };
// Shall be implemented later
export function RenderBoundary(Component) {
    return function (props) {
        return React.createElement(Boundary, null,
            React.createElement(Component, __assign({}, props)));
    };
}
export function useStateStoreProvider() {
    var trackLength = BIGSTORE.track.length;
    if (trackLength) {
        return BIGSTORE.track[trackLength - 1];
    }
    return NaN;
}
export function getStateStore(_a) {
    var storeId = _a.storeId, providerId = _a.providerId;
    var providerStore = BIGSTORE.stores[providerId];
    if (!providerStore)
        return null;
    return providerStore[storeId].value || null;
}
export function setStateStoreAction(_a) {
    var storeId = _a.storeId, providerId = _a.providerId, action = _a.action;
    var providerStore = BIGSTORE.stores[providerId];
    if (!providerStore)
        return;
    var store = providerStore[storeId];
    if (!store)
        return;
    var subscribers = store.subscribers, subscriptionCount = store.subscriptionCount;
    subscribers[subscriptionCount] = action;
    return store.subscriptionCount++;
}
export function removeStateStoreAction(_a) {
    var storeId = _a.storeId, providerId = _a.providerId, subscriptionId = _a.subscriptionId;
    var providerStore = BIGSTORE.stores[providerId];
    if (!providerStore)
        return;
    var store = providerStore[storeId];
    if (!store)
        return;
    store.subscribers[subscriptionId] = noOperation;
}
export function updateStateStore(_a) {
    var storeId = _a.storeId, providerId = _a.providerId, store = _a.store;
    var stores = BIGSTORE.stores;
    var providerStore = stores[providerId];
    if (!providerStore)
        return;
    providerStore[storeId] && providerStore[storeId].setValue(store);
}
export function createStateStore(_a) {
    var storeId = _a.storeId, providerId = _a.providerId, store = _a.store;
    var _b = store, state = _b[0], setState = _b[1];
    var stores = BIGSTORE.stores;
    var providerStore = stores[providerId];
    if (!providerStore[storeId]) {
        providerStore[storeId] = {
            storeId: storeId,
            value: state,
            subscriptionCount: LEAST_NUMBER,
            subscribers: {},
            setValue: undefined
        };
    }
    var customSetstate = function (newState) {
        var providerStore = BIGSTORE.stores[providerId];
        if (providerStore) {
            var store_1 = providerStore[storeId];
            if (store_1) {
                var subscribers = store_1.subscribers;
                var previousState = store_1.value;
                store_1.value = newState;
                setState(newState);
                for (var subscriberId in subscribers) {
                    subscribers[subscriberId](newState, previousState);
                }
                return;
            }
        }
        setState(newState);
    };
    providerStore[storeId].setValue = customSetstate;
    return [state, customSetstate];
}
