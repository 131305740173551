
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HomePage } from './components/home';
import { ProjectPage as Stylescope } from './components/react-stylescope';
import { ProjectPage as Xyntax } from './components/project';



const App = ()=>{

  return (
    <BrowserRouter>
      <Routes>
          <Route index  path='/' element={<HomePage />} />
          <Route path='/projects/react-stylescope' element={<Stylescope/>} />
          <Route path='/projects/xyntax' element={<Xyntax/>} />
      </Routes>
  </BrowserRouter>
  )
}


export default App;
