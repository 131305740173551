
export const _1 = `<div class="xmk-js1x"> 
  <span class="xmk-key1x">const</span> <span class="xmk-varx">config</span> =
  &#123;
      <span class="xmk-varx">loader</span>: <span class="xmk-varx">require</span>.<span class="xmk-methx">resolve</span>(<span class="xmk-stringx">'react-stylescope&#47;dist&#47;lib&#47;loader.js'</span>)
  &#125;

 </div>`; // \insert webpack_config 

export const _2 = `<div class="xmk-js1x"> 
  <span class="xmk-key2x">import</span> &#123; <span class="xmk-methx">ScopedStyleVitePlugin</span> &#125; <span class="xmk-key2x">from</span> <span class="xmk-stringx">"react-stylescope&#47;dist&#47;lib&#47;vite-loader"</span> 

  <span class="xmk-commentx">&#47;&#47; A possible config may looked like this
</span>   <span class="xmk-key2x">export</span> <span class="xmk-key2x">default</span> <span class="xmk-methx">defineConfig</span>(&#123;
      <span class="xmk-varx">plugins</span>: [<span class="xmk-methx">react</span>(), <span class="xmk-varx">ScopedStyleVitePlugin</span>()] <span class="xmk-commentx">&#47;&#47; Added to the plugin array
</span>  &#125;);
  
 </div>`; // \insert vite_config 

export const _3 = `<div class="xmk-js1x"> 
  <span class="xmk-key2x">import</span> &#123; <span class="xmk-methx">useScopedStyleSheet</span>, <span class="xmk-methx">getScopedName</span>, <span class="xmk-varx">ScopedStyleSheets</span> &#125; <span class="xmk-key2x">from</span> <span class="xmk-stringx">'react-stylescope'</span> 

  <span class="xmk-key1x">const</span> <span class="xmk-varx">Card</span> = (&#123;<span class="xmk-varx">backgroundColor</span>, <span class="xmk-varx">caption</span>&#125;)=&#62;&#123;

      <span class="xmk-key1x">const</span> <span class="xmk-varx">scoped</span> = <span class="xmk-varx">getScopedName</span>(<span class="xmk-stringx">'card'</span>)

      <span class="xmk-key1x">const</span> &#123; <span class="xmk-varx">keys</span>, <span class="xmk-varx">sheet</span> &#125; = <span class="xmk-varx">useScopedStyleSheet</span>(&#123;
          <span class="xmk-stringx">'.card-container'</span>: &#123;
              <span class="xmk-varx">width</span>: <span class="xmk-stringx">'320px'</span>,
              <span class="xmk-varx">backgroundColor</span>: <span class="xmk-stringx">&#96;≎06324215133523154_#123;backgroundColor&#125;&#96;</span>
          &#125;,
          <span class="xmk-stringx">'.card-container img'</span>:&#123;
              <span class="xmk-varx">lineHeight</span>: 1.5,
              <span class="xmk-varx">display</span>: <span class="xmk-stringx">'block'</span>,
              <span class="xmk-varx">margin</span>: <span class="xmk-stringx">'10px'</span>
          &#125;
      &#125;, <span class="xmk-varx">scoped</span>)

      <span class="xmk-key2x">return</span> (
          &#60;<span class="xmk-key1x">div</span> <span class="xmk-varx">className</span>=&#123;<span class="xmk-varx">keys</span>[<span class="xmk-stringx">'.card-container'</span>]&#125; &#62;
              &#60;<span class="xmk-key1x">div</span>&#62;&#60;<span class="xmk-key1x">img</span> <span class="xmk-varx">src</span>=<span class="xmk-stringx">'image.png'</span> <span class="xmk-varx">alt</span>=<span class="xmk-stringx">'Some text'</span>  &#47;&#62;&#60;&#47;<span class="xmk-key1x">div</span>&#62;
              &#60;<span class="xmk-key1x">div</span>&#62;&#123;<span class="xmk-varx">caption</span>&#125;&#60;&#47;<span class="xmk-key1x">div</span>&#62;
              &#60;<span class="xmk-varx">ScopedStyleSheets</span> <span class="xmk-varx">styles</span>=&#123;<span class="xmk-varx">sheet</span>&#125; &#47;&#62;
          &#60;&#47;<span class="xmk-key1x">div</span>&#62;
      )
  &#125;

 </div>`; // \insert card_example 




 





