import React from 'react'
import './button.css'

/**
 * 
 * @param {{style:React.CSSProperties}} param0 
 * @returns 
 */
export const TextButton = ({title,onClick,style})=>{

    return (
        <div className="design-container design-button">
            <button style={style?style:{}} className="design-container design-button" onClick={onClick}>{title}</button>
        </div>
    )
}